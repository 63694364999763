<template>
  <div class="device_control">
    <div class="container">
      <!-- 按钮 和 关闭 -->
      <div class="head">
        <div class="title">{{ deviceInfo.addr }}</div>
      </div>

      <!-- 控制区域 -->
      <div class="control_box">
        <div class="info_box">
          <div class="air">
            <img
              v-if="deviceInfo.run"
              src="../../../assets/air_open.png"
              alt=""
            />
            <img v-else src="../../../assets/air_close.png" alt="" />
          </div>
          <div class="btn_box">
            <div class="btn2">
              <div class="left">
                <span class="desc">设备状态：</span>
                <span class="state">{{
                  deviceInfo.state ? '在线' : '离线'
                }}</span>
              </div>
              <div v-if="deviceInfo.fault">
                <el-tooltip
                  effect="light"
                  :content="deviceInfo.fault_desc"
                  placement="left"
                >
                  <i class="el-icon-warning" style="color: #e4576a"></i>
                </el-tooltip>
              </div>
            </div>
            <div class="btn">
              <span class="desc">空调信号：</span>
              <span class="state">{{ deviceInfo.rssi || '--' }}</span>
            </div>
            <div class="btn">
              <span class="desc">空调品牌：</span>
              <span class="desc2">{{ deviceInfo.trademark || '--' }}</span>
            </div>
            <div class="btn">
              <span class="desc">空调型号：</span>
              <span class="desc2">{{ deviceInfo.device_model || '--' }}</span>
            </div>
            <div class="btn">
              <span class="desc">管理员：</span>
              <span class="desc3">
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="deviceInfo.account.tel"
                  placement="left"
                  v-if="deviceInfo.account && deviceInfo.account.real_name"
                >
                  <span>{{ deviceInfo.account.real_name }}</span>
                </el-tooltip>
                <span v-else>--</span>
              </span>
            </div>
            <div class="btn2">
              <div class="left">
                <span class="desc">空调锁定：</span>
                <span class="desc2">{{
                  deviceInfo.locked ? '开启' : '关闭'
                }}</span>
              </div>
              <i
                class="el-icon-arrow-right icon cursor"
                @click="changeLock"
              ></i>
            </div>

            <div class="rule_btn">
              <div class="left_box">
                <div class="desc">
                  控制规则：{{ deviceInfo.task && deviceInfo.task.name }}
                </div>
                <div
                  class="program"
                  v-if="
                    deviceInfo.task &&
                      deviceInfo.task.program &&
                      deviceInfo.task.program.name
                  "
                >
                  <img src="../../../assets/wind.png" alt="" />
                  <span>{{ deviceInfo.task.program.name }}</span>
                </div>
              </div>
              <img
                v-if="deviceInfo.task && deviceInfo.task.id_task"
                class="del_rule"
                src="../../../assets/del_rule.png"
                alt=""
                @click="showDelDeviceRule"
              />
            </div>
          </div>
        </div>
        <div class="control_btn">
          <!-- 设置温度盒子 -->
          <div class="temp_box">
            <div class="desc">设定温度</div>
            <div class="con">
              <div class="sub cursor" @click="changeTemp(-1)"></div>
              <div class="temp">
                <div class="num">{{ deviceInfo.tempSet }}</div>
                <div class="unit">℃</div>
              </div>
              <div class="add cursor" @click="changeTemp(1)"></div>
            </div>
            <div class="inner_temp">室内温度{{ deviceInfo.tempIn }}℃</div>
          </div>

          <!-- 开关机盒子 -->
          <div class="elec_box">
            <div
              class="switch cursor"
              @click="changeRun"
              :class="deviceInfo.run ? 'right' : ''"
            >
              <div class="circle"></div>
            </div>
            <span class="ele_status">{{
              deviceInfo.run ? '开机' : '关机'
            }}</span>
          </div>

          <!-- 模式盒子 -->
          <div class="mode_box">
            <div class="mode_desc">
              <div class="desc">模式</div>
              <div class="status">{{ deviceInfo.mode | modeFmt }}</div>
            </div>

            <div class="mode_select">
              <div class="mode" v-for="(item, index) in modeList" :key="index">
                <template v-if="isGray">
                  <img
                    v-if="item.id === mode"
                    @click="changeMode(item.id)"
                    :src="item.img3"
                    class="cursor"
                    alt=""
                  />
                  <img
                    v-else
                    @click="changeMode(item.id)"
                    :src="item.img"
                    class="cursor"
                    alt=""
                  />
                  <span>{{ item.name }}</span>
                </template>
                <template v-else>
                  <img
                    @click="changeMode(item.id)"
                    v-if="mode === item.id"
                    :src="item.img2"
                    class="cursor"
                    alt=""
                  />
                  <img
                    @click="changeMode(item.id)"
                    v-else
                    :src="item.img"
                    class="cursor"
                    alt=""
                  />
                  <span>{{ item.name }}</span>
                </template>
              </div>
            </div>
          </div>

          <!-- 风速盒子 -->
          <div class="wind_box">
            <div class="desc_box">
              <div class="desc">调节风速</div>
              <div class="wind">
                {{ deviceInfo.wind > 100 ? '自动风' : deviceInfo.wind }}
              </div>
            </div>
            <div class="wind_process">
              <div
                @click="changeWind(1)"
                class="process border1 cursor"
                :class="getWindClass(1)"
              ></div>
              <div
                @click="changeWind(2)"
                class="process cursor"
                :class="getWindClass(2)"
              ></div>
              <div
                @click="changeWind(3)"
                class="process cursor"
                :class="getWindClass(3)"
              ></div>
              <div
                @click="changeWind(4)"
                class="process cursor"
                :class="getWindClass(4)"
              ></div>
              <div
                @click="changeWind(5)"
                class="process cursor"
                :class="getWindClass(5)"
              ></div>
              <div
                @click="changeWind(6)"
                class="process border6 cursor"
                :class="getWindClass(6)"
              ></div>
            </div>
          </div>

          <!-- 扫风 -->
          <div class="wind_guide_box">
            <div class="item">
              <div>左右扫风</div>
              <el-switch
                v-model="windLeftRight"
                active-color="#13ce66"
                inactive-color="#313d57"
                @change="changeWindAndHot($event, 1)"
              >
              </el-switch>
            </div>

            <div class="item">
              <div>上下扫风</div>
              <el-switch
                v-model="windUpDown"
                active-color="#13ce66"
                inactive-color="#313d57"
                @change="changeWindAndHot($event, 2)"
              >
              </el-switch>
            </div>

            <div class="item">
              <div>电辅热</div>
              <el-switch
                v-model="elecHeat"
                active-color="#13ce66"
                inactive-color="#313d57"
                @change="changeWindAndHot($event, 3)"
              >
              </el-switch>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Alert ref="alertRef" @confirm="confirmEvent" />
  </div>
</template>

<script>
import {
  GetDeviceInfoApi,
  ControlDeviceApi,
  LockedDeviceApi,
  UnlockedDeviceApi,
  DelDeviceRuleApi
} from '@/api'
import mode1 from '../../../assets/mode1.png'
import mode2 from '../../../assets/mode2.png'
import mode3 from '../../../assets/mode3.png'
import mode4 from '../../../assets/mode4.png'
import mode5 from '../../../assets/mode5.png'
import mode1ative from '../../../assets/mode_1.png'
import mode2ative from '../../../assets/mode_2.png'
import mode3ative from '../../../assets/mode_3.png'
import mode4ative from '../../../assets/mode_4.png'
import mode5ative from '../../../assets/mode_5.png'
import mode1outline from '../../../assets/mode_outline_1.png'
import mode2outline from '../../../assets/mode_outline_2.png'
import mode3outline from '../../../assets/mode_outline_3.png'
import mode4outline from '../../../assets/mode_outline_4.png'
import mode5outline from '../../../assets/mode_outline_5.png'
import Alert from '@/components/alert/alert.vue'
import { modeFmt } from '@/utils/filter.js'

export default {
  components: { Alert },
  data() {
    return {
      // 设备号
      id_device: '',
      // 设备信息
      deviceInfo: {},
      // 离线或者设备关机, 显示灰色
      isGray: false,
      // 开关机
      isOpen: false,
      // 模式
      modeList: [
        {
          id: 1,
          name: '自动',
          img: mode1,
          img2: mode1ative,
          img3: mode1outline
        },
        {
          id: 2,
          name: '制冷',
          img: mode2,
          img2: mode2ative,
          img3: mode2outline
        },
        {
          id: 4,
          name: '制热',
          img: mode3,
          img2: mode3ative,
          img3: mode3outline
        },
        {
          id: 3,
          name: '除湿',
          img: mode4,
          img2: mode4ative,
          img3: mode4outline
        },
        {
          id: 5,
          name: '送风',
          img: mode5,
          img2: mode5ative,
          img3: mode5outline
        }
      ],
      // 空调模式
      mode: '',
      // 左右扫风
      windLeftRight: false,
      // 上下扫风
      windUpDown: false,
      // 电辅热, 制热才能开通电辅热
      elecHeat: false
    }
  },
  methods: {
    // 获取设备信息
    async getDeviceInfo() {
      const params = {
        id_device: this.id_device
      }
      const { ret, data, msg } = await GetDeviceInfoApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      // 控制整体样式的灰度显示
      if (!data.state || !data.run) {
        this.isGray = true
      } else {
        this.isGray = false
      }
      this.mode = data.mode
      this.deviceInfo = data
      if (data.windLeftRight) {
        this.windLeftRight = true
      } else {
        this.windLeftRight = false
      }

      if (data.windUpDown) {
        this.windUpDown = true
      } else {
        this.windUpDown = false
      }

      if (data.elecHeat) {
        this.elecHeat = true
      } else {
        this.elecHeat = false
      }
    },

    // 修改锁定状态
    changeLock() {
      const locked = this.deviceInfo.locked
      if (locked === 0) {
        // 加锁
        this.$refs.alertRef.alert(
          {
            title: '温馨提示',
            msg: '开启空调锁定将无法无法对空调进行任何操作'
          },
          null,
          2
        )
      } else {
        // 解锁
        this.unlockDevice()
      }
    },

    // 空调锁定
    async lockedDevice() {
      // 需要修改的东西有点多, 所以这里进行了封装
      const loading = this.$loading.service({
        lock: true,
        text: '设置中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const params = {
        id_device: this.deviceInfo.id_device
      }
      const { ret, data, msg } = await LockedDeviceApi(params)
      if (ret !== 0) {
        loading.close()
        this.$message.error(data.msg || msg)
        return
      }
      this.$message.success('锁定成功')
      this.getDeviceInfo()
      loading.close()
    },

    // 空调解锁
    async unlockDevice() {
      // 需要修改的东西有点多, 所以这里进行了封装
      const loading = this.$loading.service({
        lock: true,
        text: '设置中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const params = {
        id_device: this.deviceInfo.id_device
      }
      const { ret, data, msg } = await UnlockedDeviceApi(params)
      if (ret !== 0) {
        loading.close()
        return this.$message.error(data.msg || msg)
      }
      this.$message.success('解锁成功')
      this.getDeviceInfo()
      loading.close()
    },

    // 修改设备
    async setDevice(info) {
      const run = this.deviceInfo.run
      if (!run && !info.run) {
        return this.$message.warning('关机状态无法操作')
      }
      // 需要修改的东西有点多, 所以这里进行了封装
      const loading = this.$loading.service({
        lock: true,
        text: '设置中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const params = {
        id_device: this.deviceInfo.id_device,
        ...info
      }
      const { ret, data, msg } = await ControlDeviceApi(params)
      if (ret !== 0) {
        loading.close()
        this.$message.error(data.msg || msg)
        return
      }

      this.getDeviceInfo()
        .then(() => {
          loading.close()
        })
        .catch(() => {
          this.$message.success('设置成功, 更新失败')
          loading.close()
        })
    },

    // 点击开关机键
    changeRun() {
      const runState = this.deviceInfo.run
      const msg = `您确定要${runState ? '关闭' : '开启'}该设备吗?`
      const title = `${runState ? '关闭' : '开启'}确认`
      this.$refs.alertRef.alert(
        { title, msg },
        {
          run: 1 - runState
        },
        1
      )
    },

    // alert 弹窗事件发布
    confirmEvent({ info, type }) {
      if (type === 1) {
        this.setDevice(info)
      } else if (type === 2) {
        this.lockedDevice()
      } else if (type === 3) {
        this.delDeviceRule()
      }
    },

    // 修改温度
    changeTemp(num) {
      let temp = this.deviceInfo.tempSet
      temp = temp + num
      const info = {
        tempSet: temp
      }
      this.setDevice(info)
    },

    // 修改模式
    changeMode(mode) {
      const deivceMode = this.deviceInfo.mode
      if (deivceMode === mode) return
      const info = {
        mode
      }
      this.setDevice(info)
    },

    // 计算风类名
    getWindClass(block) {
      const wind = this.deviceInfo.wind || 0
      const windLevel = Math.ceil(wind / 20)
      if (this.isGray) {
        // 设备离线了或者是关机了
        if (block <= windLevel) {
          return 'outline'
        } else {
          return 'default'
        }
      } else {
        // 设备正常
        if (block <= windLevel) {
          return 'process' + block
        } else {
          return 'default'
        }
      }
    },

    // 改变风的大小
    changeWind(v) {
      const info = {
        wind: v * 20
      }
      this.setDevice(info)
    },

    // 修改扫风方向 和 电辅热
    changeWindAndHot(flag, type) {
      if (type === 1) {
        const info = {
          windLeftRight: flag ? 1 : 0
        }
        this.windLeftRight = !flag
        this.setDevice(info)
      } else if (type === 2) {
        const info = {
          windUpDown: flag ? 1 : 0
        }
        this.windUpDown = !flag
        this.setDevice(info)
      } else {
        // 电辅热
        const mode = this.deviceInfo.mode
        if (mode !== 4 && flag) {
          this.elecHeat = false
          return this.$message.warning('只有制热才能启用电辅热!')
        }
        const info = {
          elecHeat: flag ? 1 : 0
        }
        this.elecHeat = !flag
        this.setDevice(info)
      }
    },

    // 显示删除规则的弹窗
    showDelDeviceRule() {
      this.$refs.alertRef.alert(
        {
          title: '设备规则删除提醒',
          msg: '您确定要移除当前设备的控制规则吗?'
        },
        null,
        3
      )
    },

    // 删除设备上的规则
    async delDeviceRule() {
      const params = {
        id_device: this.deviceInfo.id_device
      }
      const { ret, data, msg } = await DelDeviceRuleApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('移除成功')
      this.getDeviceInfo()
    }
  },
  created() {
    this.id_device = this.$route.query.id
    this.getDeviceInfo()
  },
  filters: {
    modeFmt
  }
}
</script>

<style lang="scss" scoped>
.device_control {
  min-height: calc(100vh - 120px);
  background-color: #1d222e;
  padding: 24px;

  .container {
    background-color: #252b3b;
    border-radius: 4px;
    min-height: calc(100vh - 168px);
  }
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 40px 0;

  .title {
    padding: 11px 27px;
    background-color: #50e399;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-family: Alibaba;
    font-weight: normal;
    color: #ffffff;
  }

  img {
    width: 41px;
    height: 41px;
  }
}

.control_box {
  display: flex;
  justify-content: space-around;

  // 左边的图
  .info_box {
    width: 542px;

    .air {
      width: 544px;
      height: 254px;
      margin-top: 39px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .btn_box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 57px;

      .btn {
        width: 264px;
        height: 48px;
        background: #1e2434;
        border-radius: 14px;
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        padding: 0 23px;

        .desc {
          font-size: 14px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #ffffff;
        }

        .state {
          @extend .desc;
          color: #50e399;
          margin-left: 8px;
        }

        .desc2 {
          @extend .desc;
          color: #fff;
          margin-left: 8px;
        }

        .desc3 {
          @extend .desc;
          color: #fff;
          margin-left: 8px;
          text-decoration: 1px solid underline;
        }
      }

      .btn2 {
        @extend .btn;
        justify-content: space-between;

        .icon {
          color: #6c799a;
        }
      }

      .rule_btn {
        width: 542px;
        height: 48px;
        background: #1e2434;
        border-radius: 14px;
        padding: 0 23px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left_box {
          display: flex;
          align-items: center;

          .desc {
            font-size: 14px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #ffffff;
          }

          .program {
            padding: 4px 8px;
            display: flex;
            align-items: center;
            background-color: rgba(77, 218, 148, 0.2);
            border-radius: 11px;
            margin-left: 16px;

            img {
              width: 14px;
              height: 14px;
            }

            span {
              font-size: 14px;
              font-family: Alibaba PuHuiTi;
              font-weight: 400;
              color: #50e399;
              margin-left: 4px;
            }
          }
        }

        .del_rule {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  // 右边的操控按钮
  .control_btn {
    width: 464px;

    .temp_box {
      width: 464px;
      height: 154px;
      background: #1e2434;
      border-radius: 26px;
      padding-top: 20px;

      .desc {
        font-size: 14px;
        font-family: Alibaba;
        font-weight: normal;
        color: #ffffff;
        padding-left: 44px;
      }

      .con {
        display: flex;
        align-items: center;
        justify-content: center;

        .sub {
          width: 36px;
          height: 36px;
          background: url('../../../assets/sub.png') no-repeat;
          background-size: 100% 100%;
        }

        .temp {
          height: 64px;
          display: flex;
          align-items: flex-start;
          margin: 0 42px;

          .num {
            font-size: 64px;
            font-family: DINOT;
            font-weight: normal;
            color: #ffffff;
            line-height: 1;
          }

          .unit {
            font-size: 24px;
            font-family: DINOT;
            font-weight: normal;
            color: #ffffff;
          }
        }

        .add {
          width: 36px;
          height: 36px;
          background: url('../../../assets/add.png') no-repeat;
          background-size: 100% 100%;
        }
      }

      .inner_temp {
        width: 116px;
        height: 28px;
        background: #323d56;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: Alibaba;
        font-weight: normal;
        color: #ffffff;
        margin: 8px auto 0;
      }
    }

    .elec_box {
      width: 464px;
      height: 96px;
      background: #1e2434;
      border-radius: 27px;
      margin-top: 16px;
      display: flex;
      align-items: center;

      .switch {
        width: 95px;
        height: 56px;
        background-color: #323d56;
        border-radius: 28px;
        padding: 5px;
        margin: 0 24px 0 31px;
        display: flex;
        cursor: pointer;

        .circle {
          width: 46px;
          height: 46px;
          background: #ffffff;
          border-radius: 50%;
          transition: all 0.3s;
        }

        &.right {
          background: #ed9771;
          justify-content: flex-end;
        }
      }

      .ele_status {
        font-size: 18px;
        font-family: AlibabaPuHuiTi;
        font-weight: 500;
        color: #ffffff;
      }
    }

    .mode_box {
      width: 464px;
      height: 150px;
      background: #1e2434;
      border-radius: 26px;
      margin-top: 16px;

      .mode_desc {
        height: 50px;
        display: flex;
        align-items: center;

        .desc {
          font-size: 14px;
          font-family: Alibaba;
          font-weight: normal;
          color: #ffffff;
          margin: 0 11px 0 43px;
        }

        .status {
          font-size: 14px;
          font-family: Alibaba;
          font-weight: normal;
          color: #50e399;
        }
      }

      .mode_select {
        height: 86px;
        display: flex;
        padding: 0 9px;

        .mode {
          width: 58px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-left: 22px;

          img {
            width: 58px;
            height: 58px;
          }

          span {
            font-size: 14px;
            font-family: Alibaba;
            font-weight: normal;
            color: #ffffff;
          }
        }
      }
    }

    .wind_box {
      width: 464px;
      height: 134px;
      background: #1e2434;
      border-radius: 26px;
      margin-top: 16px;
      padding-top: 7px;

      .desc_box {
        display: flex;
        align-items: center;
        height: 46px;

        .desc {
          font-size: 14px;
          font-family: Alibaba;
          font-weight: normal;
          color: #ffffff;
          margin: 0 13px 0 44px;
        }

        .wind {
          font-size: 14px;
          font-family: Alibaba PuHuiTi;
          font-weight: 500;
          color: #50e399;
        }
      }

      .wind_process {
        height: 54px;
        width: 404px;
        display: flex;
        justify-content: space-between;
        padding-left: 31px;

        .process {
          height: 100%;
          width: 64px;
          margin-right: 4px;

          &.border1 {
            border-radius: 27px 0 0 27px;
          }

          &1 {
            background-color: #b6e3cc;
          }

          &2 {
            background-color: #9fe3c1;
          }

          &3 {
            background-color: #88e3b6;
          }

          &4 {
            background-color: #71e3aa;
          }

          &5 {
            background-color: #5be39f;
          }

          &6 {
            background-color: #44e394;
          }

          &.border6 {
            border-radius: 0 27px 27px 0;
          }

          &.default {
            background-color: #323d56;
          }

          &.outline {
            background-color: #565e6c;
          }
        }
      }
    }

    .wind_guide_box {
      width: 464px;
      height: 118px;
      background: #1e2434;
      border-radius: 26px;
      margin: 17px 0;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .item {
        text-align: center;

        div {
          font-size: 14px;
          font-family: Alibaba;
          font-weight: normal;
          color: #ffffff;
          opacity: 0.5;
          margin-bottom: 17px;
        }
      }
    }
  }
}
</style>
